<template>
  <div class="inter-wrapper">
    <HeaderInter />
    <div v-if="storecode === 'everrich'" class="inter-content promptwrapper">
      <h4 class="title">
        會員登入步驟說明
      </h4>
      <div class="promptmain">
        <div
          v-for="(content, index) in membercontents"
          :key="index"
          class="contentarea"
        >
          {{ index + 1 }}. {{ content.text }}
        </div>
        <div class="textnote">
          ※ 購買/兌換任意票券或商品，請先綁定會員。
        </div>
      </div>
      <div v-if="checkPlatform() === 'web'" class="qrcodearea">
        <qrcode-vue :value="qrcodeval" :size="size" level="H" />
      </div>
      <div class="btn-group-bottom">
        <button
          v-if="checkPlatform() !== 'web'"
          class="btn btn-next"
          @click="toAPP"
        >
          點我 登入/註冊綁定
        </button>
      </div>
    </div>
    <div v-else class="inter-content promptwrapper">
      <h4 class="title">
        會員登入辦法與步驟
      </h4>
      <div class="promptmain">
        <div
          v-for="(content, index) in membercontents"
          :key="index"
          class="contentarea"
        >
          {{ index + 1 }}. {{ content.text }}
        </div>
        <div class="textnote">
          ※ 購買任意票卷或商品, 請先登入會員。
        </div>
      </div>
      <div v-if="checkPlatform() === 'web'" class="qrcodearea">
        <qrcode-vue :value="qrcodeval" :size="size" level="H" />
      </div>
      <div class="btn-group-bottom">
        <button
          v-if="checkPlatform() !== 'web'"
          class="btn btn-next"
          @click="toAPP"
        >
          點我 登入/註冊綁定
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import QrcodeVue from 'qrcode.vue'
import HeaderInter from '@/components/HeaderInter'
import { checkPlatform, getStoreCode, getStoreappurl } from '@/utils/tool'
export default {
  name: 'Prompt',
  components: { HeaderInter, QrcodeVue },
  data() {
    return {
      size: 200,
      contents: [
        {
          storecode: 'everrich',
          content: [
            {
              type: 'web',
              content: [
                {
                  text: '請使用手機掃描下方QRcode完成iRich會員【註冊/綁定】。'
                },
                {
                  text: '點選【紅利酬賓】進行票券兌換。'
                }
              ]
            },
            {
              type: 'app',
              content: [
                {
                  text: '請點選下方按鈕完成iRich會員【註冊/綁定】。'
                },
                {
                  text: '點選【紅利酬賓】進行票券兌換。'
                }
              ]
            }
          ]
        },
        {
          storecode: 'icashpay',
          content: [
            {
              type: 'web',
              content: [
                {
                  text:
                    '請使用手機點擊【登入/註冊綁定】按鈕或掃描下方 QRcode，開啟 icashpay App。'
                },
                {
                  text: '登入 icashpay App 進入首頁→點擊享樂券，即可登入商城！'
                }
              ]
            },
            {
              type: 'app',
              content: [
                {
                  text:
                    '請使用手機點擊【登入/註冊綁定】按鈕或掃描下方 QRcode，開啟 icashpay App。'
                },
                {
                  text: '登入 icashpay App 進入首頁→點擊享樂券，即可登入商城！'
                }
              ]
            }
          ]
        }
      ]
    }
  },
  computed: {
    storecode() {
      return getStoreCode()
    },
    qrcodeval() {
      return getStoreappurl()
    },
    membercontents() {
      let content = []
      const curcontrent = this.contents.find(e => {
        return e.storecode === getStoreCode()
      })
      if (checkPlatform() === 'web') {
        content = curcontrent.content.find(e => {
          return e.type === 'web'
        })
      } else {
        content = curcontrent.content.find(e => {
          return e.type === 'app'
        })
      }
      return content.content
    }
  },
  created() {},
  destroyed() {},
  methods: {
    checkPlatform() {
      return checkPlatform()
    },
    toAPP() {
      window.location.href = this.qrcodeval
    }
  }
}
</script>
<style lang="scss">
@import 'assets/scss/common.scss';
.promptwrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    font-weight: bold;
  }
  .promptmain {
    width: 90%;
    margin: 0 auto;
    .contentarea {
      margin: 1rem auto;
    }
    .textnote {
      color: rgb(224, 14, 14);
    }
  }
  .qrcodearea {
    margin-top: 3rem;
    margin-bottom: 1rem;
  }
  .btn-group-bottom {
    display: flex;
    margin: 3rem 1rem;
    justify-content: center;
    width: 100%;
  }
}
</style>
